import React from "react";
import banner_vision from "../../assets/images/banner_history-1536x288-1.jpg";

const History = () => {
  return (
    <div className="history">
      <div className="head_img">
        <img src={banner_vision} style={{width: '100%' , height: '100%' , objectFit: 'contain'}} alt="banner" />        
      </div>
      <div className="content">
        <div className="history_head">
          <h2>इतिहास</h2>
        </div>
        <div className="details">
          <p>
            शंकरराव ओगले, नानासाहेब भावे, मुकुंदराव कुलकर्णी, श्रीराम मंत्री इ.
            शिक्षण क्षेत्रातील कार्यकर्त्यांनी 'भारतीय शिक्षण 'मासिक पत्रिका
            चालू करण्याचे ठरविले. त्याप्रमाणे ऑगस्ट 1967 मध्ये पहिला अंक
            प्रकाशित झाला. या अंकात मासिकाचा मुख्य उद्देश स्पष्ट केला. 1)
            शिक्षणाचे भारतीयकरण 2)महाराष्ट्रातील शिक्षकाला त्याच्या प्रश्नाबद्दल
            काही लिहायचे आहे, बोलायचे आहे. त्यासाठी त्याला व्यासपीठ पाहिजे आहे.
            यासाठी या मासिकाचा जन्म झाला.
          </p>
          <p>
            काही महिन्यात च अन्य प्रांतातील कार्यकर्ते एकत्र आले. समकालीन
            शैक्षणिक प्रश्न समान असल्यामुळे हे व्यासपीठ अखिल भारतीय स्तरावर काम
            करू शकेल असा विश्वास व्यक्त झाल्यामुळे भारतीय शिक्षण मंडळ ही संघटना
            एप्रिल 1969 मध्ये मुंबई, अंधेरी येथे स्थापन केली. या संस्थेचा एक
            उपक्रम म्हणून भारतीय शिक्षण मासिक कार्य करेल असेही ठरले. काल
            परिस्थिती नुसार इ.1999-2000 मध्ये हे मासिक व अन्य शैक्षणिक उपक्रम
            यासाठी महाराष्ट्र भारतीय शिक्षण शिक्षण मंडळ या संस्थेची स्वतंत्र पणे
            नोंदणी करावी असा विचार केला गेला.
          </p>
          <p>
            त्याप्रमाणे इ.2000 मध्ये पुणे धर्मादाय आयुक्तांकडे याबाबत अधिकृत पणे
            नोंदणी करण्यात आली. व त्याचे नोंदणी कृत कार्यालय म्हणून पुणे
          </p>
        </div>
      </div>
    </div>
  );
};

export default History;
